<template>
  <div v-if="isMobile" class="container">
    <div class="audioVisual-container">
      <div class="audioVisual-title">
        <div
          v-for="(item, key) in firstTitleList"
          :key="key"
          @click="changeTab(key)"
          class="title"
          :class="{ activeTitle: activeKey === key }"
        >
          {{ item }}
        </div>
      </div>
      <div class="audioVisual-wrap">
        <div class="audioVisual-content" v-show="activeKey === 0">
          <div class="second-titleList">
            <div
              v-for="(secondTitle, titleKey) in secondTitleList"
              :key="titleKey"
              @click="changeSecondTab(secondTitle.mid)"
              :class="{
                activeSecondTitle: actiSecondveKey === secondTitle.mid,
              }"
            >
              {{ secondTitle.name }}
            </div>
          </div>
          <div class="second-content scrollStyle">
            <div
              v-for="(item, key) in videoList"
              :key="key"
              class="audio-wrap"
              @click="showPlayWrap(item.youtube_id)"
            >
              <div class="audio-picture">
                <img :src="item.img" alt="" />
              </div>
              <div class="audio-name">{{ item.title || item.intro }}</div>
              <div class="audio-play">
                <div class="player"></div>
              </div>
            </div>
          </div>
        </div>
        <div class="audioVisual-content" v-show="activeKey === 1">
          <div class="second-content scrollStyle">
            <div
              v-for="(item, key) in imgList"
              :key="key"
              class="audio-wrap"
              @click="showImgWrap(item.img)"
            >
              <div class="image">
                <img :src="item.img" alt="" />
              </div>
              <div class="audio-name">{{ item.title }}</div>
            </div>
            <viewer :images="images">
              <img v-for="src in images" :key="src" :src="src" />
            </viewer>
          </div>
        </div>
      </div>
    </div>

    <div
      class="video-container"
      v-show="isShowPlayWrap"
      @click.stop="closePlayWrap()"
    >
      <iframe
        :src="src"
        frameborder="0"
        allowfullscreen="allowfullscreen"
      ></iframe>
    </div>
  </div>
  <div v-else class="container">
    <div class="audioVisual-container">
      <div class="audioVisual-title">
        <div
          v-for="(item, key) in firstTitleList"
          :key="key"
          @click="changeTab(key)"
          class="title"
          :class="{ activeTitle: activeKey === key }"
        >
          {{ item }}
        </div>
      </div>
      <div class="audioVisual-wrap">
        <div class="audioVisual-content" v-show="activeKey === 0">
          <div class="second-titleList">
            <div
              v-for="(secondTitle, titleKey) in secondTitleList"
              :key="titleKey"
              @click="changeSecondTab(secondTitle.mid)"
              :class="{
                activeSecondTitle: actiSecondveKey === secondTitle.mid,
              }"
            >
              {{ secondTitle.name }}
            </div>
          </div>
          <div class="second-content scrollStyle">
            <div
              v-for="(item, key) in videoList"
              :key="key"
              class="audio-wrap"
              @click="showPlayWrap(item.youtube_id)"
            >
              <div class="audio-picture">
                <img :src="item.img" alt="" />
              </div>
              <div class="audio-name">{{ item.title || item.intro }}</div>
              <div class="audio-play">
                <div class="player"></div>
              </div>
            </div>
          </div>
        </div>
        <div class="audioVisual-content" v-show="activeKey === 1">
          <div class="second-content scrollStyle">
            <div v-for="(item, key) in imgList" :key="key" class="audio-wrap">
              <div class="image" @click="showImage(key)">
                <img :src="item.img" alt="" />
              </div>
              <div class="audio-name">{{ item.title || item.intro }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="video-container"
      v-show="isShowPlayWrap"
      @click.stop="closePlayWrap()"
    >
      <div class="video-wrap">
        <!-- <div class="close" @click="closePlayWrap()"></div> -->
        <div class="border-top"></div>
        <div class="border-right"></div>
        <div class="border-bottom"></div>
        <div class="border-left"></div>
        <div class="video-content">
          <iframe
            :src="src"
            frameborder="0"
            allowfullscreen="allowfullscreen"
            width="100%"
            height="85%"
          ></iframe>
        </div>
      </div>
    </div>
    <div class="pagination" v-show="pagenumberTotal > 1 && activeKey === 0">
      <div class="first" @click="jumpPages(1)"></div>
      <div class="front" @click="jumpPages(2)"></div>
      <div
        class="pagenumber"
        v-for="item in pagenumberTotal"
        :key="item"
        :class="{ activePageNumber: pageActive === item }"
        @click="turnPages(item)"
      >
        <div>
          <span>
            {{ item }}
          </span>
        </div>
      </div>
      <div class="after" @click="jumpPages(3)"></div>
      <div class="last" @click="jumpPages(4)"></div>
    </div>
  </div>
</template>

<script>
import { isMobile } from "@/utils/util";
import { mapMutations } from "vuex";
//引入video样式
import "video.js/dist/video-js.css";
import "vue-video-player/src/custom-theme.css";

//引入hls.js
import "videojs-contrib-hls.js/src/videojs.hlsjs";

import { ImagePreview } from "vant";

import {
  getChildList,
  getVideoList,
  // getArticle,
  // VudilPlayer,
  getImgList,
} from "@/api/index";
export default {
  name: "AudioVisual",
  data() {
    return {
      src: "",
      firstTitleList: ["ムービー", "グラフィック"],
      contentList: [0, 1],
      secondTitleList: [],
      activeKey: 0,
      actiSecondveKey: 0,
      isShowPlayWrap: false,
      isMobile,
      playerOptions: {
        playbackRates: [0.7, 1.0, 1.5, 2.0], //播放速度
        autoplay: false, //如果true,浏览器准备好时开始回放。
        muted: false, // 默认情况下将会消除任何音频。
        loop: false, // 导致视频一结束就重新开始。
        preload: "auto", // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: "zh-CN",
        aspectRatio: "16:9", // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [
          {
            // type: "application/x-mpegURL", //这里的种类支持很多种：基本视频格式、直播、流媒体等，具体可以参看git网址项目
            // type: "video/mp4",
            // src: "https://www.fosunsports.com/video/TR5Q1634196312001.mp4", //url地址
            // src: require("../assets/audio.mp4"),
          },
        ],
        poster: "https://test.fosunsports.com/images/0M641634628099254.jpg", //你的封面地址
        // width: document.documentElement.clientWidth, //播放器宽度
        notSupportedMessage: "此视频暂无法播放，请稍后再试", //允许覆盖Video.js无法播放媒体源时显示的默认信息。
        controlBar: {
          timeDivider: true,
          durationDisplay: true,
          remainingTimeDisplay: false,
          fullscreenToggle: true, //全屏按钮
        },
      },
      videoList: [],
      isShowPagination: false,
      pagenumberTotal: 0,
      pageActive: 1,
      imgList: [],
    };
  },
  watch: {
    src: {
      handler(newval) {
        // console.log(newval);
        this.playerOptions.sources[0].src = newval;
        // console.log(this.playerOptions.sources[0].src, '视频赋值');
        this.srcvideo = newval;
      },
    },
  },
  methods: {
    ...mapMutations(["increment"]),
    changeTab(key) {
      this.activeKey = key;
      // console.log(id);
    },
    changeSecondTab(mid) {
      this.actiSecondveKey = mid;
      this.pageActive = 1;
      this.getVideoList(mid);
    },
    showPlayWrap(id) {
      console.log(id);
      this.src =
        "https://www.youtube.com/embed/" +
        id +
        "?autoplay=1&controls=1&disablekb=1&&rel=0&loop=1&enablejsapi=1&mute=1&arel=0";
      this.isShowPlayWrap = true;
    },
    showImage(key) {
      if (!this.previewList) {
        this.previewList = this.imgList.map((item) => {
          return item.img;
        });
      }
      this.$viewerApi({
        images: this.previewList,
        options: {
          initialViewIndex: key,
        },
      });
    },
    showImgWrap(id) {
      ImagePreview({ images: [id], showIndex: false });
    },
    closePlayWrap() {
      this.isShowPlayWrap = false;
      this.src = "";
    },
    getVideoList(mid, p) {
      console.log(mid, p);
      getVideoList({ mid: mid || 8, num: 12, p: p || 1 }).then((res) => {
        console.log(res.data);
        const { totalPage, list } = res.data;
        this.pagenumberTotal = totalPage;
        this.videoList = list;
      });
    },
    turnPages(number) {
      // console.log(number);
      this.pageActive = number;
      console.log(this.actiSecondveKey, number);
      this.getVideoList(this.actiSecondveKey, number);
    },
    jumpPages(number) {
      const pageActive = this.pageActive;
      switch (number) {
        case 1:
          this.getVideoList(this.actiSecondveKey, 1);
          this.pageActive = 1;
          break;
        case 2:
          if (pageActive > 1) {
            this.getVideoList(this.actiSecondveKey, pageActive - 1);
            this.pageActive = pageActive - 1;
          }
          break;
        case 3:
          if (pageActive < this.pagenumberTotal) {
            this.getVideoList(this.actiSecondveKey, pageActive + 1);
            this.pageActive = pageActive + 1;
          }
          break;
        case 4:
          this.getVideoList(this.actiSecondveKey, this.pagenumberTotal);
          this.pageActive = this.pagenumberTotal;
          break;
        default:
          break;
      }
      // this.pageActive = number;
    },
  },
  created() {
    this.playerOptions.sources[0].src = this.src;
  },
  mounted() {
    this.increment({ status: 3 });
    getChildList({ mid: 5 }).then((res) => {
      console.log(1, res);
      this.secondTitleList = res.data;
      const videoKey = this.$route.query.videoKey || "8";
      this.actiSecondveKey = videoKey;
      this.getVideoList(parseInt(videoKey));
    });
    Promise.all([
      getImgList({ mid: 9, num: 3 }),
      getImgList({ mid: 10, num: 3 }),
      getImgList({ mid: 11, num: 3 }),
    ]).then((result) => {
      // console.log(2, result);
      this.imgList = [
        ...result[0].data.list,
        ...result[1].data.list,
        ...result[2].data.list,
      ];
    });
  },
};
</script>

<style lang="scss" scoped>
@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media (min-width: 751px) {
  .container {
    background: url(../assets/img/audio/bg.png) no-repeat bottom;
    background-size: cover;
    position: relative;
    min-height: calc(100% - 87px);
    padding-top: 87px;
  }

  .audioVisual-title {
    display: flex;
    // justify-content: center;
    background-image: url(../assets/img/titleWrap.png);
    background-size: cover;
    background-position: right;
    background-repeat: no-repeat;
    padding-left: 78px;
    margin-bottom: 42px;
    .title {
      height: 84px;
      width: 248px;
      line-height: 84px;
      text-align: center;
      color: #15385b;
      cursor: pointer;
      font-size: 28px;
    }
    .activeTitle {
      background: url(../assets/img/newsCheckBoeder.png);
      background-size: cover;
      // width: 248px;
      padding-left: 65px;
      width: 258px;
      padding-right: 10px;
      box-sizing: border-box;
    }
  }

  .video-list {
    height: 632px;
    overflow: auto;
  }
  .video-list::-webkit-scrollbar {
    width: 11px;
    height: 10px;
  }

  .second-titleList {
    display: flex;
    align-items: center;
    padding-left: 50px;
    .activeSecondTitle {
      height: 78px;
      background: url(../assets/img/audio/checkSecond.png) no-repeat;
      background-size: contain;
      background-position: center;
      border-bottom: none;
      line-height: 78px;
    }
  }
  .second-titleList > div {
    height: 60px;
    font-size: 26px;
    margin: 0 22px;
    padding: 0 14px;
    line-height: 60px;
    border-bottom: 2px solid #a7d5e5;
  }

  .second-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    width: 1430px;
    margin: 0 auto;
    padding-top: 50px;
    // padding-right: 70px;
    padding-left: 50px;
  }
  .audio-wrap {
    width: 300px;
    margin-bottom: 26px;
    margin-right: 50px;
    position: relative;
    .audio-picture {
      height: 135px;
      cursor: pointer;
      position: relative;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .audio-name {
      line-height: 36px;
      text-align: center;
      background: #afdbec;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      word-break: break-all;
    }
  }

  .video-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba($color: #000000, $alpha: 0.2);
    .video-wrap {
      width: 1070px;
      height: 600px;
      position: absolute;
      top: 0;
      bottom: -50px;
      left: 0;
      right: 0;
      margin: auto;
      .close {
        position: absolute;
        top: 16px;
        right: 54px;
        width: 51px;
        height: 124px;
        background: url(../assets/img/audio/close.png) no-repeat;
        background-size: cover;
      }
      .border-top,
      .border-right,
      .border-bottom,
      .border-left {
        position: absolute;
        z-index: 6;
        background-repeat: no-repeat;
        background-size: 100% 100%;
      }
      .border-top {
        width: 126%;
        height: 60px;
        top: -21px;
        left: -129px;
        background-image: url(../assets/img/audio/border-top.png);
      }
      .border-right {
        top: 0;
        right: -147px;
        width: 163px;
        height: 100%;
        background-image: url(../assets/img/audio/border-right.png);
      }
      .border-bottom {
        bottom: -78px;
        left: -129px;
        width: 126%;
        height: 85px;
        background-image: url(../assets/img/audio/border-bottom.png);
      }
      .border-left {
        left: -127px;
        top: 0;
        width: 141px;
        height: 100%;
        background-image: url(../assets/img/audio/border-left.png);
      }
    }
  }
  .video-player {
    width: 80%;
    /* height: 300px; */
    margin: 0 auto;
    padding: 20px;
  }
  .video-content {
    width: 100%;
    margin: 0 auto;
    // padding: 20px;
    height: 100%;
    position: relative;
    // left: -8px;
    video {
      width: 100%;
      height: 100%;
    }
    iframe {
      width: 100%;
      height: 100%;
    }
  }

  .pagination {
    // height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 40px;
    padding-top: 27px;
  }
  .pagination > div {
    margin-right: 7.5px;
    cursor: pointer;
  }
  .first,
  .front,
  .after,
  .last {
    width: 45px;
    height: 45px;
    background-size: cover;
  }
  .first {
    background-image: url(../assets/img/new/first.png);
  }
  .front {
    background-image: url(../assets/img/new/front.png);
  }
  .after {
    background-image: url(../assets/img/new/after.png);
  }
  .last {
    background-image: url(../assets/img/new/last.png);
  }
  .pagenumber {
    width: 26px;
    height: 26px;
    padding: 2px;
    border: 1px solid #92cae1;
    transform: rotate(45deg);
    margin: 0 8px;
  }
  .pagenumber > div {
    height: 100%;
    background: #92cae1;
    color: #15385b;
  }
  .activePageNumber {
    border: 1px solid #15385b;
  }
  .activePageNumber > div {
    background: #15385b;
    color: #fff;
  }
  .pagenumber > div > span {
    display: inline-block;
    height: 100%;
    width: 100%;
    transform: rotate(-45deg);
    text-align: center;
    line-height: 23px;
  }

  .mr5 {
    margin-right: 5px;
  }

  .image {
    height: 135px;
    img {
      width: 100%;
      height: 100%;
    }
  }

  .audio-play {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: rgba($color: #000000, $alpha: 0.5);
    .player {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      height: 92px;
      width: 92px;
      // background: #fff;
    }
    .player:before,
    .player::after {
      content: "";
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
      margin: auto;
    }
    .player::before {
      width: 30px;
      height: 32px;
      background: url(../assets/img/audio/sanjiao.png) no-repeat 50%/100% 100%;
    }
    .player::after {
      width: 100%;
      height: 100%;
      background: url(../assets/img/audio/sanjiaoBG.png) no-repeat 50%/100% 100%;
      animation: rotate 15s linear infinite;
    }
  }
}

@media (max-width: 750px) {
  .container {
    background: url(../assets/img/audio/bg.png) no-repeat bottom;
    background-size: cover;
    position: relative;
    // min-height: calc(100% - 87px);
    padding-top: 0.85rem;
    box-sizing: border-box;
    height: 100vh;
    overflow: hidden;
  }

  .audioVisual-container {
    // height: 90vh;
    // overflow: hidden;
  }

  .audioVisual-title {
    display: flex;
    // justify-content: center;
    background-image: url(../assets/img/titleWrap.png);
    background-size: cover;
    background-position: right;
    background-repeat: no-repeat;
    width: 97vw;
    margin: 0.16rem auto;
    .title {
      height: 0.9rem;
      width: 2.2rem;
      line-height: 0.9rem;
      text-align: center;
      color: #15385b;
      cursor: pointer;
      font-size: 0.28rem;
    }
    .activeTitle {
      background: url(../assets/img/app/newsCheckBorder.png);
      background-size: cover;
      // width: 248px;
      position: relative;
    }
    .activeTitle::before {
      content: "";
      display: block;
      position: absolute;
      top: 0.05rem;
      left: 0.06rem;
      width: 0.35rem;
      height: 0.4rem;
      background: url(../assets/img/app/newsCheckIcon.png) no-repeat;
      background-size: contain;
    }
  }

  .second-titleList {
    display: flex;
    align-items: center;
    // padding-left: 26px;
    justify-content: space-between;
    width: 97vw;
    margin: 0 auto;
    .activeSecondTitle {
      // height: 1.25rem;
      background: url(../assets/img/audio/checkSecond.png) no-repeat;
      background-size: 100% 100%;
      background-position: center;
      border-bottom: none;
      // line-height: 1.25rem;
    }
  }
  .second-titleList > div {
    font-size: 0.2rem;
    line-height: 0.7rem;
    border-bottom: 1px solid #a7d5e5;
    width: 32%;
    text-align: center;
  }

  .second-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 93vw;
    max-height: 60vh;
    margin: 0 auto;
    padding-top: 0.2rem;
    padding-bottom: 2rem;
    // height: 580px;
    overflow: auto;
    // padding-right: 70px;
  }
  .audio-wrap {
    width: 49%;
    margin-bottom: 0.1rem;
    position: relative;
    // margin-right: 50px;
    .audio-picture {
      // height: 135px;
      position: relative;
      img {
        width: 100%;
        height: 2.1rem;
      }
    }
    .audio-name {
      font-size: 0.3rem;
      line-height: 1.5;
      text-align: center;
      background: #afdbec;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      word-break: break-all;
    }
  }

  .video-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    // background: rgba($color: #000000, $alpha: 0.2);
    z-index: 31;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    .iframe {
      width: 90%;
      height: auto;
    }
  }

  .image {
    img {
      width: 100%;
      height: 2.4rem;
    }
  }

  .audio-play {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: rgba($color: #000000, $alpha: 0.5);
    .player {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      height: 1.8rem;
      width: 1.8rem;
      // background: #fff;
    }
    .player:before,
    .player::after {
      content: "";
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
      margin: auto;
    }
    .player::before {
      width: 0.4rem;
      height: 0.5rem;
      background: url(../assets/img/audio/sanjiao.png) no-repeat 50%/100% 100%;
    }
    .player::after {
      width: 100%;
      height: 100%;
      background: url(../assets/img/audio/sanjiaoBG.png) no-repeat 50%/100% 100%;
      animation: rotate 15s linear infinite;
    }
  }
}
</style>
